import React from 'react';
import { Route, NavLink, HashRouter } from 'react-router-dom';
import Landing from './Landing';
import Itinerary from './Itinerary';
import HotList from './HotList';
import './scss/App.scss';

const App = () => {
  const toggleHandler = () => {
    document.querySelector('.navbar__toggle').classList.toggle('navbar__toggle--open');
    document.querySelector('.navbar__menu-list').classList.toggle('navbar__menu-list--open');
  };

  const closeMenu = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Remove classes from elements
    document.querySelector('.navbar__toggle').classList.remove('navbar__toggle--open');
    document.querySelector('.navbar__menu-list').classList.remove('navbar__menu-list--open');
  };

  return (
    <HashRouter>
      <div className='app'>
        <div className="bg" style={{ backgroundImage: 'url(./images/background.jpg)' }}></div>
        <nav className='navbar'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell navbar__cell'>
                <button className='navbar__toggle' onClick={toggleHandler}>
                  <div className='navbar__label'>MENU</div>
                  <div className='navbar__toggle-icon'>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className='navbar__menu'>
            <div className='grid-container'>
              <div className='grid-x grid-padding-x'>
                <div className='small-12 cell'>
                  <ul className='navbar__menu-list'>
                    <li className='navbar__menu-list-item'>
                      <NavLink
                        className='navbar__menu-list-item-link'
                        to='/'
                        exact={true}
                        onClick={closeMenu}>
                        Home
                      </NavLink>
                    </li>
                    <li className='navbar__menu-list-item'>
                      <NavLink
                        className='navbar__menu-list-item-link'
                        to='/itinerary'
                        onClick={closeMenu}>
                        Itinerary
                      </NavLink>
                    </li>
                    <li className='navbar__menu-list-item'>
                      <NavLink
                        className='navbar__menu-list-item-link'
                        to='/hot-list'
                        onClick={closeMenu}>
                        Hot List
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <main className='main'>
          <Route exact path='/' component={Landing} />
          <Route path='/itinerary' component={Itinerary} />
          <Route path='/hot-list' component={HotList} />
        </main>
      </div>
    </HashRouter>
  );
};

export default App;
