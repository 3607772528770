import React from 'react';
import { NavLink } from 'react-router-dom';

const Landing = () => {
  return (
    <section className="landing section">
      <div className="center">
        <img className="hennessy lazyload" data-src="./images/hennessy.svg" alt="" />
      </div>
      <img className="bras-arme lazyload" data-src="./images/bras-arme.svg" alt="" />
      <div className="landing__xo">
        <p>HENNESSY INVITES YOU TO A VOYAGE INTO<br className="hide-sm"/>THE X.O HIGH ENERGY UNIVERSE</p>
        <img className="lazyload" data-src="./images/xo-logo.png" alt="" />
      </div>
      <div className="landing__copy">
        <h1>LAS VEGAS, NEVADA<br />10.03 - 10.06.2024<br />ITINERARY & INFORMATION</h1>
        <NavLink className="enter-btn" to="/itinerary">ENTER</NavLink>
      </div>
    </section>
  );
};

export default Landing;
