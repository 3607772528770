import React from 'react';

const HotList = () => {
  return (
    <section className="hotlist section">
      <div className="center">
        <img className="hennessy lazyload" data-src="./images/hennessy.svg" alt="" />
      </div>
      <img className="bras-arme lazyload" data-src="./images/bras-arme.png" alt="" />
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="small-12 small-offset-0 medium-12 medium-offset-0 large-8 large-offset-2 cell">
            <div className="section__inner">
              <div className="center">
                <img className="section__logo lazyload" data-src="./images/xo-logo.png" alt="" />
              </div>
              <hr />
              <h1>HOT LIST</h1>
              <p>We invite you to visit Las Vegas’ finest Hennessy X.O partners. If you require assistance in booking a reservation, please send your request to the X.O Le Cercle Privé Team at <a href="mailto:X.OLeCerclePrive@MeridianEvents.com" target="_blank" rel="noopener noreferrer">X.OLeCerclePrive@MeridianEvents.com</a></p>
              <hr/>
              <h2>DINING</h2>
              <div className="row">
                <div className="hotlist__venue pull-left">
                  <h4>Cathédrale<br/>ARIA</h4>
                  <p>3730 S Las Vegas Blvd<br/>+1.702.590.8577</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/cathedrale-aria.jpg" alt=""/>
                  <p>A Mediterranean restaurant envisioned by Executive Chef Jason Hall, taking culinary inspiration from Southern France with traces of Italy, Spain, and Greece.</p>
                </div>
                <div className="hotlist__venue pull-right">
                  <h4>Delilah<br/>Wynn</h4>
                  <p>3131 Las Vegas Blvd S<br/>+1.702.770.3300</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/delilah-wynn.jpg" alt=""/>
                  <p>A modern-day supper club offering cocktails, dining, and entertainment inspired by 1950s Las Vegas showrooms, with live music and special performances.</p>
                </div>
              </div>
              <div className="row">
                <div className="hotlist__venue pull-left">
                  <h4>Don’s Prime<br/>Fontainebleau</h4>
                  <p>2777 S Las Vegas Blvd<br/>+1.702.678.9000</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/dons-prime-fontainebleau.jpg" alt=""/>
                  <p>Experience steakhouse perfection, where exquisite old-school service meets exceptional American flavor.</p>
                </div>
                <div className="hotlist__venue pull-right">
                  <h4>FUHU<br/>Resorts World</h4>
                  <p>3000 S Las Vegas Blvd<br/>+1.702.676.7740</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/fuhu-resorts-world.jpg" alt=""/>
                  <p>Creative cocktails & choice Asian fare served in a vibe dining setting featuring a DJ & terrace seating.</p>
                </div>
              </div>
              <div className="row">
                <div className="hotlist__venue pull-left">
                  <h4>Mayfair<br/>Bellagio</h4>
                  <p>3600 S Las Vegas Blvd<br/>+1.702.693.8876</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/mayfair-bellagio.jpg" alt=""/>
                  <p>Inspired by top supper clubs worldwide and offering a glamorous dining experience, live performances, and late-night shows – all set against the iconic Fountains of Bellagio.</p>
                </div>
                <div className="hotlist__venue pull-right">
                  <h4>Wing Lei<br/>Encore</h4>
                  <p>3131 Las Vegas Blvd S<br/>+1.702.770.3388</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/wing-lei-encore.jpg" alt=""/>
                  <p>The first Chinese restaurant in North America to earn a Michelin star. Offering Cantonese, Shanghai, and Szechuan delicacies served in a lavish gold dining room overlooking century-old pomegranate trees.</p>
                </div>
              </div>
              <div className="row center">
                <div className="hotlist__venue">
                  <h4>STK<br/>Cosmopolitan</h4>
                  <p>3708 S Las Vegas Blvd<br/>+1.702.698.7990</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/stk-cosmopolitan.jpg" alt=""/>
                  <p>Hip, lively restaurant & lounge serving steaks in a dark, ultra-modern setting.</p>
                </div>
              </div>
              <hr/>
              <h2>LOUNGES</h2>
              <div className="row">
                <div className="hotlist__venue pull-left">
                  <h4>Alle Lounge on 66<br/>Resorts World</h4>
                  <p>3000 S Las Vegas Blvd<br/>+1.702.676.7766</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/alle-lounge.jpg" alt=""/>
                  <p>Features expansive views of the Las Vegas Strip from 66 floors up, with fine and rare spirits, handcrafted cocktails, and globally inspired small plates.</p>
                </div>
                <div className="hotlist__venue pull-right">
                  <h4>Eight Cigar Lounge<br/>Resorts World</h4>
                  <p>3000 S Las Vegas Blvd<br/>+1.702.676.7405</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/eight-cigar-lounge.jpg" alt=""/>
                  <p>Sophisticated venue offering a curated selection of premium cigars, spirits, and a refined ambiance.</p>
                </div>
              </div>
              <div className="row">
                <div className="hotlist__venue pull-left">
                  <h4>Gatsby’s<br/>Resorts World</h4>
                  <p>3000 S Las Vegas Blvd<br/>+1.702.676.6013</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/gatsbys-resorts-world.jpg" alt=""/>
                  <p>Upscale escape offering a cache of vintage and rare champagnes, vivid artisanal cocktails and aromatic wines.</p>
                </div>
                <div className="hotlist__venue pull-right">
                  <h4>Overlook<br/>Wynn</h4>
                  <p>3131 Las Vegas Blvd S<br/>+1.702.770.3392</p>
                  <img className="lazyload hotlist__image" data-src="./images/hot-list/overlook-wynn.jpg" alt=""/>
                  <p>Luxurious lakeside lounge offering elevated cocktails, light bites, and stunning views in a serene, sophisticated setting.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotList;
