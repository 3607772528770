import React from 'react';

const Itinerary = () => {
  return (
    <section className="itinerary section">
      <div className="center">
        <img className="hennessy lazyload" data-src="./images/hennessy.svg" alt="" />
      </div>
      <img className="bras-arme lazyload" data-src="./images/bras-arme.png" alt="" />
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="small-12 small-offset-0 medium-12 medium-offset-0 large-8 large-offset-2 cell">
            <div className="section__inner">
              <div className="center">
                <img className="section__logo lazyload" data-src="./images/xo-logo.png" alt="" />
              </div>
              <hr />
              <h1>ITINERARY</h1>
              <div className="itinerary__day">
                <h2>DAY 1 | THURSDAY, OCTOBER 3<sup>RD</sup></h2>
                <div className="itinerary__event">
                  <p><b>All Day | At Leisure</b></p>
                </div>
                <div className="itinerary__event">
                  <p><b>7pm - 9pm | X.O Welcome Soirée</b></p>
                  <p><em>Hosted by Maurice Hennessy, 8th Generation of the Hennessy Family, and Jordan Bushell, Global Brand Ambassador</em></p>
                  <ul>
                    <li>Location | Poodle Room, Exclusive Members-Only Club at Fontainebleau</li>
                    <li>Suggested Attire | Smart Business Casual</li>
                  </ul>
                </div>
                <div className="itinerary__event">
                  <p><b>Night | It’s not a trip to Vegas without a trip to the Fontainebleau Casino. Let’s continue the evening’s festivities with new friends – we’ll bring the X.O.</b></p>
                </div>
              </div>
              <hr className="itinerary__day-hr" />
              <div className="itinerary__day">
                <h2>DAY 2 | FRIDAY, OCTOBER 4<sup>TH</sup></h2>
                <div className="itinerary__event">
                  <p><b>9am - 1:30pm | Welcome to the World of X.O: Conquering Every Curve</b></p>
                  <ul>
                    <li>Location | Meet in the Fontainebleau Lobby for transportation</li>
                    <li>Suggested Attire | Comfortable, breathable clothing and closed-toe shoes. Pants are recommended. Please be aware that there is a risk of your clothing getting dirty.</li>
                  </ul>
                </div>
                <div className="itinerary__event">
                  <p><b>Afternoon | Join the Hennessy team for a lively lunch and engaging conversations at the vibrant Fontainebleau.</b></p>
                </div>
                <div className="itinerary__event">
                  <p><b>7:30pm – 9pm | A Sensorial X.O Cocktail Journey</b></p>
                  <p><em>Hosted by Jordan Bushell, Global Brand Ambassador</em></p>
                  <ul>
                    <li>Location | Nowhere Lounge, Bespoke Speakeasy at Fontainebleau</li>
                    <li>Suggested Attire | Upscale Fashion Attire</li>
                  </ul>
                </div>
                <div className="itinerary__event">
                  <p><b>9pm - 11:30pm | X.O Unveiled: Vegas Vibe Dining</b></p>
                  <ul>
                    <li>Location | Komodo, Fontainebleau</li>
                    <li>Suggested Attire | Upscale Fashion Attire</li>
                  </ul>
                </div>
                <div className="itinerary__event">
                  <p><b>12am and Onward | X.O Radiates the Night</b></p>
                  <ul>
                    <li>Location | LIV, Fontainebleau</li>
                    <li>Suggested Attire | Upscale Fashion Attire. Swimwear, sports/athletic apparel, and casual loungewear is not permitted by LIV.</li>
                    <li>Please note that you must present a photo ID (e.g. driver’s license or passport) to enter LIV.</li>
                  </ul>
                </div>
              </div>
              <hr className="itinerary__day-hr" />
              <div className="itinerary__day">
                <h2>DAY 3 | SATURDAY, OCTOBER 5<sup>TH</sup></h2>
                <div className="itinerary__event">
                  <p><b>12pm - 4pm | X.O Mirage: An Illuminating Odyssey</b></p>
                  <ul>
                    <li>Location | Meet in the Fontainebleau Lobby for transportation</li>
                    <li>Suggested Attire | Comfortable, breathable clothing and closed-toe shoes. Hat, sunglasses, and sunscreen are recommended.</li>
                  </ul>
                </div>
              </div>
              <hr className="itinerary__day-hr" />
              <div className="itinerary__day">
                <h2>DAY 4 | SUNDAY, OCTOBER 6<sup>TH</sup></h2>
                <div className="itinerary__event">
                  <p><b>All Day | Departures At Leisure</b></p>
                  <ul>
                    <li>Ground transportation to Harry Reid Las Vegas International Airport (LAS) will be arranged for each guest by the X.O Le Cercle Privé Team.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Itinerary;
